import React, { Component } from 'react';
import { Card, Form, Layout, Input, text,Button, message, Select, Divider} from 'antd';
import './Setting.scss';
import Cookies from 'universal-cookie';
import intl from 'react-intl-universal';
import { Link } from "react-router-dom";
const cookies = new Cookies();
const { Option } = Select;

const { Header, Content, } = Layout;
class Setting extends Component {
  constructor(props){
    super(props);
    this.submit = this.submit.bind(this);
    this.state = {
      isShow:"none",
      inputWidth:"100%",
      areaCode:'86',
      color1:"#006dd3",
      color2:"#A8A8A8",
      domain : global.Api.domain_url(),
      store: null,
    }
  }

  componentDidMount(){
    this.getStore();
  }

  getStore(){
    global.Api.getStore().then(res=>{
      if (res.data.code === 0) {
        let _data = res.data.data;
        this.setState({store:_data.store,logo:_data.store.logo,});
        // if(this.props.location.pathname === '/login'){
        //   this.props.history.push('/reports')
        // }
        this.props.form.setFieldsValue({
          name: _data.store.name,
          address: _data.store.address,
          telephone: _data.store.contact,
          email: _data.store.email,
          wear_threshold_low: _data.store.wear_threshold_low,
          wear_threshold_high: _data.store.wear_threshold_high,
        })
      }
    })
  }

  handleChange = (value) => {
    value = Number(value);
    return value
  }

  handleChangeOversea = (value) => {
    this.setState({
      areaCode:value
    })
  }
  showSelect = (code)=>{
    if(code===0){
      this.setState({
            isShow:"none",
            inputWidth:"100%",
            color1:"#006dd3",
            color2:"#A8A8A8",
      })
    }else {
      this.setState(
          {
            isShow:"inline-block",
            inputWidth:"70%",
            color1:"#A8A8A8",
            color2:"#006dd3"
        }
      )
    }

  }

  submit(e){
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        global.Api.updateStore(values).then(res=>{
          if(res.data.code === 0){
            let _data = res.data.data;
            this.setState({store:_data.store});   
            message.info('Submit successfully!');        
            return
          }
          message.error(res.data.message);
        })
      }
    });
  }

  goback(){
    window.history.back();
  }

  logout = () => {
    const {history} = this.props;    
    cookies.remove('tyrehub-pc');
    history.push('/login');
    // window.open('/login');
  }

  render() {
    const { getFieldDecorator } = this.props.form;
    
    return (
      <Layout className="reports">
        <Header style={{position: 'fixed', zIndex: 1, width: '100%',height:'120px',backgroundColor:'#3385ff',color:"#fff",}}>
          <div style={{marginRight:"20px",color:"#fff"}} >
          <span style={{fontSize:"20px",color:"#fff"}}>Handy Tyre Scanner</span>
          {/* <img class="logo img-fluid" src="https://mobilhubdiagnostics.com/img/logo-1667829529.jpg" alt='' width="228" height="38" /> */}
          </div>
          <Button type="link" style={{marginLeft:"10px",color:"#fff",fontSize:'15px',fontWeight:'500',}} ><Link to="/dashboard">{intl.get('dashboard')}</Link></Button>
          <Button type="link" style={{marginLeft:"10px",color:"#fff",fontSize:'15px',fontWeight:'500',}} ><Link to="/reports">{intl.get('reports')}</Link></Button>
          <Button type="link" style={{marginLeft:"10px",color:"#fff",fontSize:'15px',fontWeight:'500',}} ><Link to="/devices">{intl.get('devices')}</Link></Button>
          <Button type="link" style={{marginRight:"10px",color:"#fff",fontSize:'15px',fontWeight:'500',}} ><Link to="/setting">{intl.get('setting')}</Link></Button>
                    
        </Header>
        <Content style={{ padding: '0 50px', marginTop:'110px'}}>
          <div className="setting">
            {/* <Button type="primary" style={{position:"absolute",left:"20px",top:"18px"}} onClick={this.downloadApp}>{intl.get('awd')}</Button> */}
            <Card title={intl.get('setting')} style={{ width: 1000 }}>
            <div style={{ alignItems: 'right',justifyContent:'right',display:'flex' }}>
                <Button type="link" style={{marginRight:"10px",fontSize:'13px',fontWeight:'300',}} ><Link to="/changepassword">Change Password</Link></Button>
                <Button type="link" style={{marginRight:"10px",fontSize:'13px',fontWeight:'300',}} onClick={this.logout}>Logout</Button>
            </div>
            <Form onSubmit={this.submit}>
              <Form.Item>
                <text>Store Name (  ID:{this.state.store?this.state.store.id:''}   UUID:   {this.state.store?this.state.store.uuid:''}  MERCHANTID:   {this.state.store?this.state.store.merchant:''}  )</text>
                
                {getFieldDecorator('name', {
                  rules: [{ required: true, message: 'please input store name' }],
                })(
                <Input />
                )}
              </Form.Item>
              <Form.Item>
                <text>Store Address</text>
                {getFieldDecorator('address', {
                  rules: [{ required: true, message: 'please input store address' }],
                })(
                <Input />
                )}
              </Form.Item>
              <Form.Item>
                <text>Store Telephone</text>
                {getFieldDecorator('telephone', {
                  rules: [{ required: true, message: 'please input store telephone' }],
                })(
                <Input />
                )}
              </Form.Item>
              <Form.Item>
                <text>Store Email</text>
                {getFieldDecorator('email', {
                  rules: [{ required: false, message: 'please input store email' }],
                })(
                <Input />
                )}
              </Form.Item>
              {(this.state.domain !== 'taihapo.com.cn' &&
              <Form.Item>
                <text>Wear Threshold Low</text>
                {getFieldDecorator('wear_threshold_low', {
                  rules: [{ required: false, message: 'please input store wear threshold low value' }],
                })(
                <Input placeholder='The default value is 25' />
                )}
              </Form.Item>)}
              {(this.state.domain !== 'taihapo.com.cn' &&
              <Form.Item>
                <text>Wear Threshold High</text>
                {getFieldDecorator('wear_threshold_high', {
                  rules: [{ required: false, message: 'please input store wear threshold high value' }],
                })(
                <Input placeholder='The default value is 45' />
                )}
              </Form.Item>      )}        
              
              <Form.Item>
                <text>Store Logo</text>   
                {/* <span style={{marginLeft:"30px"}}>Upload a new logo</span> */}
                <Button type="link" style={{marginLeft:"30px",color:"##000",fontSize:'15px',}} ><Link to="/uploadfile">Upload a new Logo</Link></Button>
        
                {getFieldDecorator('logo', {
                  rules: [{ required: false, message: 'store logo' }],
                })(
                <img src={this.state.logo} alt="image"/>
                )}
              </Form.Item>


              <Form.Item>
                <Button type="primary" htmlType="submit" style={{marginLeft:"0%",width:'45%'}}>
                {intl.get('confirm')}
                </Button>
                <Button type="primary" onClick={this.goback} style={{marginLeft:"10%",width:'45%'}}>
                {intl.get('cancel')}
                </Button>
              </Form.Item>
            </Form>
            </Card>
          </div>
        </Content>
      </Layout>
    );
  }
}

export default Form.create()(Setting);
